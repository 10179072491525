import React from 'react'
import Link from 'next/link'
import { HomeIcon, ChevronRightIcon } from 'lucide-react'

export interface Breadcrumb {
  href: string
  label: string
  current?: boolean
}

interface PageHeaderProps {
  title: string
  description?: string
  breadcrumbs?: Breadcrumb[]
  action?: React.ReactNode
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  breadcrumbs,
  action,
}) => {
  return (
    <div className='pb-1 sm:pb-3'>
      <div className='container mx-auto px-4 sm:px-6 lg:px-8'>
        {/* Breadcrumbs */}
        {breadcrumbs && breadcrumbs.length > 0 && (
          <nav className='mb-4 flex' aria-label='Breadcrumb'>
            <ol className='flex items-center space-x-2'>
              <li>
                <div className='flex items-center'>
                  <Link href='/' className='text-gray-500 hover:text-gray-700'>
                    <HomeIcon className='h-4 w-4' aria-hidden='true' />
                    <span className='sr-only'>Home</span>
                  </Link>
                </div>
              </li>
              {breadcrumbs.map((breadcrumb, index) => (
                <li key={breadcrumb.href}>
                  <div className='flex items-center'>
                    <ChevronRightIcon
                      className='h-4 w-4 text-gray-400'
                      aria-hidden='true'
                    />
                    {breadcrumb.current ? (
                      <span
                        className='ml-2 text-sm font-medium text-gray-700'
                        aria-current='page'
                      >
                        {breadcrumb.label}
                      </span>
                    ) : (
                      <Link
                        href={breadcrumb.href}
                        className='ml-2 text-sm font-medium text-gray-500 hover:text-gray-700'
                      >
                        {breadcrumb.label}
                      </Link>
                    )}
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        )}

        {/* Title and Actions */}
        <div className='flex flex-row items-center justify-between'>
          <div>
            <div className='flex items-baseline'>
              <h1 className='text-3xl font-extrabold tracking-tight text-gray-900'>
                {title}
              </h1>
            </div>
            {description && (
              <p className='mt-1 text-sm text-gray-500'>{description}</p>
            )}
          </div>

          {/* Action */}
          {action && <div className='mt-4 flex md:mt-0'>{action}</div>}
        </div>
      </div>
    </div>
  )
}
