// React
import { Fragment, useMemo, useState } from 'react'

// Next
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'

// Components
import Logo from '@/components/blocks/logo'
import { Button } from '@/components//ui/button'
import { Footer } from '@/components/layouts/footer'
import { SideNav } from '@/components/layouts/side-nav'
import { LanguageSelector } from '@/components/blocks/language-selector'
import { LoggedOutSideNav } from '@/components/layouts/logged-out-side-nav'
import { PageHeader } from '@/components/layouts/page-header'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

// Utils
import { cn } from '@/utils/index'
import { supabaseBrowserClient as supabase } from '@/utils/supabase-browser-client'
import {
  useSession,
  useRefreshSession,
  useUserDetails,
  useSubscription,
} from '@/utils/auth/user-context'

// Headless UI
import { Dialog, Transition } from '@headlessui/react'

// Icons
import {
  AlertTriangleIcon,
  ChevronsUpDownIcon,
  CircleUserRoundIcon,
  DownloadIcon,
  MenuIcon,
  PowerIcon,
  XIcon,
  ZapIcon,
} from 'lucide-react'

// External
import { useTranslation } from 'next-i18next'

type LayoutProps = {
  children: React.ReactNode
}

const BaseLayout: React.FC<LayoutProps> = ({ children }) => {
  const session = useSession()
  const subscription = useSubscription()
  const [userDetails] = useUserDetails()
  const refreshSession = useRefreshSession()
  const router = useRouter()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { t } = useTranslation(['common'])

  const routeInfo = {
    '/account/my-templates': {
      title: t('layouts.baseLayout.routes.myTemplates.title'),
      description: t('layouts.baseLayout.routes.myTemplates.description'),
    },
    '/account/my-memes': {
      title: t('layouts.baseLayout.routes.myMemes.title'),
      description: t('layouts.baseLayout.routes.myMemes.description'),
    },
    '/account/my-prompts': {
      title: t('layouts.baseLayout.routes.myPrompts.title'),
      description: t('layouts.baseLayout.routes.myPrompts.description'),
    },
    '/account/saved-memes': {
      title: t('layouts.baseLayout.routes.starredMemes.title'),
      description: t('layouts.baseLayout.routes.starredMemes.description'),
    },
    '/meme-generator': {
      title: t('layouts.baseLayout.routes.memeGenerator.title'),
      description: t('layouts.baseLayout.routes.memeGenerator.description'),
    },
    '/meme-maker': {
      title: t('layouts.baseLayout.routes.memeMaker.title'),
      description: t('layouts.baseLayout.routes.memeMaker.description'),
    },
    '/meme/*': {
      title: t('layouts.baseLayout.routes.memeDetails.title'),
      description: t('layouts.baseLayout.routes.memeDetails.description'),
    },
    '/search*': {
      title: t('layouts.baseLayout.routes.search.title'),
      description: t('layouts.baseLayout.routes.search.description'),
    },
    '/text-to-meme': {
      title: t('layouts.baseLayout.routes.textToMeme.title'),
      description: t('layouts.baseLayout.routes.textToMeme.description'),
    },
  }

  const getHeaderTitle = useMemo(() => {
    const fn = () => {
      const routeKey = Object.keys(routeInfo).find(
        (key) => router.pathname.startsWith(key.replace('*', '')) // support wildcard routes
      )

      if (!routeKey) return null

      // Handle meme detail pages specially
      if (routeKey === '/meme/*') {
        // Extract meme name from path
        const memeName = router.query.image as string
        if (!memeName) return null

        // Format the meme name to be more readable
        const formattedName = memeName
          .replace(/\.[^/.]+$/, '') // Remove extension
          .split('-')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ')

        return (
          <PageHeader
            title={`${formattedName} ${t('layouts.baseLayout.routes.memeDetails.titleSuffix')}`}
            breadcrumbs={[
              {
                href: '/search',
                label: t(
                  'layouts.baseLayout.routes.memeDetails.breadcrumbs.templates'
                ),
              },
              {
                href: '#',
                label: formattedName,
                current: true,
              },
            ]}
            action={
              <Button
                variant='ghost'
                size='sm'
                className='inline-flex items-center p-0 text-sm text-gray-500 hover:text-gray-700'
                onClick={() => {
                  const link = document.createElement('a')
                  link.href = `https://supermeme-space-prd.ams3.cdn.digitaloceanspaces.com/templates/${memeName}`
                  link.download = formattedName
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }}
              >
                <DownloadIcon className='mr-1 h-4 w-4' />
                {t(
                  'layouts.baseLayout.routes.memeDetails.actions.downloadTemplate'
                )}
              </Button>
            }
          />
        )
      }

      // Handle regular routes
      const { title, description } = routeInfo[routeKey] || {}
      if (!title || !description) return null

      return <PageHeader title={title} description={description} />
    }

    return fn
  }, [router, routeInfo, t])

  const renderLogoutButton = useMemo(() => {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <div className='flex w-full cursor-pointer items-center space-x-3 outline-none focus:outline-none'>
            <div>
              {userDetails?.avatar_url ? (
                <img // eslint-disable-line
                  className='inline-block h-9 w-9 rounded-full'
                  src={userDetails?.avatar_url}
                  alt='Profile picture'
                />
              ) : (
                <CircleUserRoundIcon
                  className='h-6 w-6 rounded-full'
                  aria-hidden='true'
                />
              )}
            </div>
            <div>
              <p className='text-left text-sm font-medium text-gray-700 group-hover:text-gray-900'>
                {userDetails?.full_name ?? 'Chief meme officer'}
              </p>
            </div>
            <ChevronsUpDownIcon className='h-5 w-5 text-gray-700 group-hover:text-gray-900' />
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <Button
            variant='ghost'
            className='w-full border border-red-600 text-red-600 hover:bg-gray-200 hover:text-red-700'
            onClick={async () => {
              await supabase.auth.signOut()
              refreshSession()
              router.replace('/login')
              setSidebarOpen(false)
            }}
          >
            <PowerIcon
              className='mr-3 h-4 w-4 flex-shrink-0'
              aria-hidden='true'
            />
            {t('layouts.baseLayout.buttons.logout')}
          </Button>
        </PopoverContent>
      </Popover>
    )
  }, [userDetails, refreshSession, router, t])

  return (
    <>
      <Head>
        <title>{t('layouts.baseLayout.title')}</title>
      </Head>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-40 md:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 z-40 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute right-0 top-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XIcon
                          className='h-4 w-4 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex flex-1 flex-col overflow-y-auto pb-4 pt-5'>
                    <div className='flex flex-shrink-0 items-center px-4'>
                      <Logo />
                    </div>
                    <nav className='mt-5 flex flex-1 flex-col space-y-8 px-2'>
                      {session && <SideNav setSideBarOpen={setSidebarOpen} />}
                      {!session && <LoggedOutSideNav />}
                    </nav>
                  </div>
                  {session && (
                    <div className='flex flex-shrink-0 border-t border-gray-200 p-4'>
                      {renderLogoutButton}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0'>
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {router.pathname !== '/login' && (
          <div className='hidden md:fixed md:inset-y-0 md:flex md:w-60 md:flex-col'>
            <div className='flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white'>
              <div className='flex flex-1 flex-col overflow-y-auto pb-4 pt-5'>
                <div className='flex flex-shrink-0 items-center px-4'>
                  <Logo />
                </div>
                <nav className='mt-5 flex flex-1 flex-col space-y-6 bg-white px-2'>
                  {session && <SideNav setSideBarOpen={setSidebarOpen} />}
                  {!session && <LoggedOutSideNav />}
                </nav>
              </div>
              {session && (
                <div className='flex flex-shrink-0 border-t border-gray-200 p-4'>
                  {renderLogoutButton}
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className={`flex min-h-screen flex-1 flex-col ${router.pathname !== '/login' ? 'md:pl-60' : ''}`}
        >
          {router.pathname !== '/login' && (
            <div className='bg-white px-1 pt-1 sm:pt-3 md:relative'>
              {/* TODO: Update this banner to be more generic so that it can be used for different scenarios */}
              {process.env.NEXT_PUBLIC_SHOW_MAINTENANCE_BANNER === 'true' && (
                <div className='mr-1 flex items-center justify-center rounded-md bg-gray-800 px-6 py-2.5 sm:mr-3 sm:px-3.5'>
                  <AlertTriangleIcon
                    className='mr-2 h-5 w-5 text-yellow-400'
                    aria-hidden='true'
                  />{' '}
                  <p className='text-center text-base font-bold leading-6 text-white'>
                    <span>
                      We are currently experiencing a high error rate from
                      OpenAI. We are working on a fix.
                    </span>
                  </p>
                </div>
              )}
              <div className='flex w-full items-center justify-between pt-1 md:hidden'>
                <Logo />
                <div className='flex items-center justify-center space-x-2'>
                  {/* mobile view */}
                  {/* TODO: add better language selector */}
                  {/* {session && <LanguageSelector />} */}
                  {!subscription && (
                    <Button variant='outline' asChild size='icon'>
                      <Link href='/account/upgrade'>
                        <ZapIcon className='h-5 w-5 text-yellow-600' />
                        <span className='sr-only'>Upgrade</span>
                      </Link>
                    </Button>
                  )}
                  <Button
                    type='button'
                    variant='outline'
                    size='icon'
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className='sr-only'>Open sidebar</span>
                    <MenuIcon className='h-5 w-5' aria-hidden='true' />
                  </Button>
                </div>
              </div>
              <div
                className={cn(
                  'hidden flex-1 justify-between px-4 md:flex',
                  !!getHeaderTitle() && 'border-b border-gray-200'
                )}
              >
                <div className='w-full'>{getHeaderTitle()}</div>
                <div className='ml-4 flex items-center md:ml-6'>
                  {/* TODO: add better language selector */}
                  {/* {session && <LanguageSelector />} */}
                </div>
              </div>
            </div>
          )}

          <main className='min-h-screen'>
            <div
              className={router.pathname !== '/login' ? 'py-8 lg:py-16' : ''}
            >
              <div className='mx-auto px-2'>{children}</div>
            </div>
          </main>

          {router.pathname !== '/login' && <Footer />}
        </div>
      </div>
    </>
  )
}

export { BaseLayout }
