// React
import React from 'react'

// Next
import Link from 'next/link'
import { useRouter } from 'next/router'

// Utils
import { classNames } from '@supermeme-ai/utils'
import {
  useRemainingCredits,
  useSubscription,
  useUserStats,
} from '@/utils/auth/user-context'

// External
import { useTranslation } from 'next-i18next'
import {
  CircleUserIcon,
  CreditCardIcon,
  FingerprintIcon,
  FlameIcon,
  GiftIcon,
  GroupIcon,
  ImageIcon,
  MessageSquareTextIcon,
  PaletteIcon,
  RocketIcon,
  SearchIcon,
  SquareCodeIcon,
  StarIcon,
  UploadCloudIcon,
  ZapIcon,
} from 'lucide-react'

type SideNavProps = {
  setSideBarOpen: (value: boolean) => void
}

const SideNav: React.FC<SideNavProps> = ({ setSideBarOpen }) => {
  const router = useRouter()
  const subscription = useSubscription()
  const remainingCredits = useRemainingCredits()
  const stats = useUserStats()
  const { t } = useTranslation(['common'])
  const hasApiAccess =
    subscription &&
    subscription?.prices?.products.metadata?.apiAccess === 'true'

  const mainNavigation = [
    {
      name: t('layouts.sideNav.main.textToMeme'),
      href: '/text-to-meme',
      icon: RocketIcon,
      badge: (
        <>
          <span className='ml-2 inline-flex items-center rounded-full bg-indigo-100 px-2 py-0.5 text-[11px] font-bold text-indigo-800'>
            {t('layouts.sideNav.main.badges.new')}
          </span>
        </>
      ),
    },
    {
      name: t('layouts.sideNav.main.templateToMeme'),
      href: '/meme-generator',
      icon: GroupIcon,
    },
    {
      name: t('layouts.sideNav.main.searchTemplates'),
      href: '/search',
      icon: SearchIcon,
    },
    {
      name: t('layouts.sideNav.main.memeMaker'),
      href: '/meme-maker',
      icon: PaletteIcon,
    },
  ]

  const secondaryNavigation = [
    {
      title: t('layouts.sideNav.history.title'),
      navigationItems: [
        {
          name: t('layouts.sideNav.history.items.myMemes'),
          href: '/account/my-memes',
          icon: ImageIcon,
        },
        {
          name: t('layouts.sideNav.history.items.myPrompts'),
          href: '/account/my-prompts',
          icon: MessageSquareTextIcon,
        },
        {
          name: t('layouts.sideNav.history.items.myTemplates'),
          href: '/account/my-templates',
          icon: UploadCloudIcon,
        },
        {
          name: t('layouts.sideNav.history.items.starredMemes'),
          href: '/account/saved-memes',
          icon: StarIcon,
        },
      ],
    },
  ]

  const tertiaryNavigation = [
    {
      title: t('layouts.sideNav.account.title'),
      navigationItems: [
        {
          name: t('layouts.sideNav.account.items.subscription'),
          href: '/account',
          icon: CreditCardIcon,
        },
        {
          name: t('layouts.sideNav.account.items.customWatermark'),
          href: '/account#watermark',
          icon: FingerprintIcon,
        },
        {
          name: t('layouts.sideNav.account.items.profile'),
          href: '/account/profile',
          icon: CircleUserIcon,
        },
        {
          name: t('layouts.sideNav.account.items.developers'),
          href: '/account/developers',
          icon: SquareCodeIcon,
          requiresApiAccess: true,
        },
      ],
    },
  ]

  return (
    <>
      <div className='space-y-1'>
        {mainNavigation.map((item) => (
          <Link href={item.href} key={item.name} legacyBehavior>
            <a
              className={classNames(
                router.asPath === item.href
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group flex items-center rounded-md px-2 py-1 text-base font-medium md:text-sm'
              )}
              onClick={() => setSideBarOpen(false)}
            >
              <item.icon
                className={classNames(
                  router.asPath === item.href
                    ? 'text-gray-900'
                    : 'text-gray-600 group-hover:text-gray-900',
                  'mr-3 h-4 w-4 flex-shrink-0'
                )}
                aria-hidden='true'
              />
              {item.name}
              {item.badge && item.badge}
            </a>
          </Link>
        ))}
      </div>

      {secondaryNavigation.map((section, index) => (
        <div className='space-y-1' key={index}>
          <h3 className='px-2 text-base font-medium text-gray-500 md:text-sm'>
            {section.title}
          </h3>
          {section.navigationItems.map((item, index) => (
            <Link key={index} href={item.href} legacyBehavior>
              <a
                role='group'
                className={classNames(
                  router.asPath === item.href
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex items-center rounded-md px-2 py-1 text-base font-medium md:text-sm'
                )}
                onClick={() => setSideBarOpen(false)}
              >
                <item.icon
                  className={classNames(
                    router.asPath === item.href
                      ? 'text-gray-900'
                      : 'text-gray-600 group-hover:text-gray-900',
                    'mr-3 h-4 w-4 flex-shrink-0'
                  )}
                  aria-hidden='true'
                />
                {item.name}
              </a>
            </Link>
          ))}
        </div>
      ))}

      {tertiaryNavigation.map((section, index) => (
        <div className='space-y-1' key={index}>
          <h3 className='px-2 text-base font-medium text-gray-500 md:text-sm'>
            {section.title}
          </h3>
          {section.navigationItems.map((item, index) =>
            (item.requiresApiAccess && hasApiAccess) ||
            !item.requiresApiAccess ? (
              <Link key={index} href={item.href} legacyBehavior>
                <a
                  role='group'
                  className={classNames(
                    router.asPath === item.href
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center rounded-md px-2 py-1 text-base font-medium md:text-sm'
                  )}
                  onClick={() => setSideBarOpen(false)}
                >
                  <item.icon
                    className={classNames(
                      router.asPath === item.href
                        ? 'text-gray-900'
                        : 'text-gray-600 group-hover:text-gray-900',
                      'mr-3 h-4 w-4 flex-shrink-0'
                    )}
                    aria-hidden='true'
                  />
                  {item.name}
                </a>
              </Link>
            ) : (
              <Link
                key='/account/upgrade'
                href='/account/upgrade'
                legacyBehavior
              >
                <a
                  className={classNames(
                    router.asPath === item.href ? 'font-bold' : 'font-medium',
                    'flex items-center bg-indigo-100 hover:bg-indigo-200',
                    'group flex items-center rounded-md px-2 py-1 text-base font-medium md:text-sm'
                  )}
                  onClick={() => setSideBarOpen(false)}
                >
                  <ZapIcon className='mr-3 h-4 w-4 flex-shrink-0 text-yellow-600' />
                  {t('layouts.sideNav.actions.getApiAccess')}
                </a>
              </Link>
            )
          )}
        </div>
      ))}

      <div className='space-y-1'>
        {!subscription && (
          <Link role='group' href='/account/upgrade' legacyBehavior>
            <a
              className='group flex items-center rounded-md bg-indigo-100 px-2 py-1 text-base font-medium text-gray-900 hover:bg-indigo-200 hover:text-gray-900 md:text-sm'
              onClick={() => setSideBarOpen(false)}
            >
              <ZapIcon
                className='mr-3 h-4 w-4 flex-shrink-0 text-yellow-600'
                aria-hidden='true'
              />
              <strong>{t('layouts.sideNav.actions.upgrade')}</strong>
            </a>
          </Link>
        )}

        {!!stats && (
          <Link
            href='/home'
            role='group'
            className='group flex items-center rounded-md px-2 py-1 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 md:text-sm'
          >
            <FlameIcon
              className='mr-3 h-5 w-5'
              style={{ color: '#f97316' }}
              fill='#f97316'
            />
            <span>
              {t('layouts.sideNav.actions.currentStreak', {
                days: stats.currentStreakInDays,
              })}
            </span>
          </Link>
        )}
      </div>

      {!subscription && (
        <div className='flex w-full flex-grow flex-col justify-end text-sm'>
          <Link
            href='/account/upgrade'
            className='group flex items-center rounded-md px-2 py-1 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 md:text-sm'
            onClick={() => setSideBarOpen(false)}
          >
            <GiftIcon
              className='mr-3 h-4 w-4 flex-shrink-0 text-gray-600'
              aria-hidden='true'
            />
            <span>
              {t('layouts.sideNav.actions.remainingCredits.free', {
                count: remainingCredits,
              })}
            </span>
          </Link>
        </div>
      )}

      {subscription &&
        subscription?.prices?.products.metadata?.limitedMonthlyCredits ===
          'true' && (
          <div className='flex w-full flex-grow flex-col justify-end text-xs'>
            <Link
              href='/account/upgrade'
              className='group flex items-center rounded-md px-2 py-1 text-xs font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 md:text-xs'
              onClick={() => setSideBarOpen(false)}
            >
              <GiftIcon
                className='mr-3 h-4 w-4 flex-shrink-0 text-gray-600'
                aria-hidden='true'
              />
              <span>
                {t('layouts.sideNav.actions.remainingCredits.monthly', {
                  count: remainingCredits,
                })}
              </span>
            </Link>
          </div>
        )}
    </>
  )
}

export { SideNav }
